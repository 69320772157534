import BlazeSlider from "blaze-slider"

export function useBlazeSlider(element,config) {
  const slider = ref(undefined)

  onMounted(() => {
    slider.value = new BlazeSlider(unref(element), config)
  })

  return slider
}